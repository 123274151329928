<template>
  <div
    class="overflow-hidden relative rounded shadow-card"
    style="border: 1px solid #eff2fd"
    v-on="$listeners"
  >
    <credit-card-3
      :credit-limit="creditLimit"
      card-status="requested"
    />
  </div>
</template>

<script>
export default {
  name: "CardEmpty",
  computed: {
    creditLimit() {
      return this.user?.profile?.credit_limit;
    },
  },
};
</script>
